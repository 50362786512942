import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Button } from 'react-bootstrap'
import ContentHeader from './ContentHeader'

const TransactionErrorContent = ({ message, onDismiss }) => {
  const theme = useContext(ThemeContext)
  return (
    <div className="modal-wrap">
      <div className="modal-section">
        <ContentHeader onDismiss={onDismiss}>Error</ContentHeader>
        <div className="text-center mt-4" >
          <i className="fas fa-exclamation-triangle text-danger" style={{ fontSize: '97px' }}></i>
        </div>
        <div style={{ fontSize: '18px' }} className="mt-4 text-danger text-center">{message}</div>
        <div className="mt-4 text-center mx-auto">
          <Button type="button" className="mx-auto" variant="primary" onClick={onDismiss}>Close</Button>
        </div>
      </div>
    </div>
  )
}

export default TransactionErrorContent
