import { ACTION_CONST, connectorLocalStorageKey, STATE } from "../../constants";
import { defaultChainId } from "../../_configs";


const initialState = {
    statusRequest: 'none',
    message:"",
    leftBarActive: true,
    isConnectWallet: false,
    web3Utils: null,
    showModalHelp: false,
    alertLink: {
        type: '',
        url: '',
        message: ''
    },
    latestBlock: 0,
    transactionHash: ""
}
export default (state = initialState, action) => {
    switch (action.type) {
        case ACTION_CONST.REQUEST_SUBMIT:
            return {
                ...state,
                statusRequest:STATE.SUBMIT,
            }
        case ACTION_CONST.REQUEST_DONE:
            return {
                ...state,
                statusRequest: 'none'
            }
        case ACTION_CONST.CLICK_LEFT_BAR_ACTIVE:
            return {
                ...state,
                leftBarActive: !state.leftBarActive,
            }
        case ACTION_CONST.ALERT_LINK:
            return {
                ...state,
                alertLink: {
                    type: action.alertType,
                    url: action.alertUrl,
                    message: action.alertMessage
                }
            };
        case ACTION_CONST.ALERT_LINK_CLEAR:
            return {
                ...state,
                alertLink: {
                    type: '',
                    url: '',
                    message: ''
                }
            };
        case ACTION_CONST.ENABLE_WALLET_SUCCESS:
            return {
                ...state,
                isConnectWallet: true,
                web3Utils: action.data

            };
        case ACTION_CONST.LOG_OUT_WALLET_SUCCESS:
            localStorage.removeItem(connectorLocalStorageKey)
            return {
                ...state,
                isConnectWallet: false,
                walletAddress: null,
                web3Utils: null
            };
        case ACTION_CONST.SET_SHOW_MODAL_HELP:
            return {
                ...state,
                showModalHelp: true
            };
        case ACTION_CONST.SET_LATEST_BLOCK:
            return {
                ...state,
                latestBlock: action.data
            }

        case ACTION_CONST.REQUEST_SUCCESS :
            return {
                ...state,
                statusRequest: STATE.SUCCESS
            }
        case ACTION_CONST.REQUEST_FAIL :
            return {
                ...state,
                statusRequest: STATE.ERROR
            }
        case ACTION_CONST.SET_MESSAGE :
            return {
                ...state,
                message: action.data
            }

        case ACTION_CONST.SET_SHOW_TRANSACTION_HASH_MODAL :
            return {
                ...state,
                transactionHash: action.data
            }
        default:
            return state
    }
}