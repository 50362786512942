export const ACTION_CONST = {
    LANGUAGE_CHANGE: 'LANGUAGE_CHANGE',
    REQUEST_SUBMIT: 'REQUEST_SUBMIT',
    REQUEST_DONE: 'REQUEST_DONE',
    REQUEST_SUCCESS:'REQUEST_SUCCESS',
    REQUEST_FAIL: 'REQUEST_FAIL',

    ALERT_SUCCESS: 'ALERT_SUCCESS',
    ALERT_FAILS: 'ALERT_FAILS',
    ALERT_WARNING: 'ALERT_WARNING',
    ALERT_CLEAR: 'ALERT_CLEAR',
    ALERT_LINK: 'ALERT_LINK',
    ALERT_LINK_CLEAR: 'ALERT_LINK_CLEAR',
    CONNECT_WALLET_SUCCESS: 'CONNECT_WALLET_SUCCESS',
    LOG_OUT_WALLET_SUCCESS: 'LOG_OUT_WALLET_SUCCESS',
    ENABLE_WALLET_SUCCESS: 'ENABLE_WALLET_SUCCESS',
    SET_SHOW_MODAL_HELP: 'SET_SHOW_MODAL_HELP',
    GET_LIST_CONTRACTS_INFO: 'GET_LIST_CONTRACTS_INFO',
    SET_CURRENT_CONTRACT_SELECTED: 'SET_CURRENT_CONTRACT_SELECTED',
    SET_LIST_CONTRACT_SELECTED: 'SET_LIST_CONTRACT_SELECTED',
    GET_INFO_WALLET: 'GET_INFO_WALLET',


    //merge staking
    SET_JOB_GET_BALANCE: 'SET_JOB_GET_BALANCE',
    GET_STAKING_INFO: 'GET_STAKING_INFO',
    SET_JOB_GET_STAKING_INFO: 'SET_GET_STAKING_INFO',
    GET_STAKING_WALLET_INFO: 'GET_STAKING_WALLET_INFO',
    SET_JOB_GET_STAKING_WALLET_INFO: 'SET_GET_STAKING_WALLET_INFO',
    SET_JOB_COUNTDOWN_STAKE_TIME: 'SET_JOB_COUNTDOWN_STAKE_TIME',
    SUBMIT_GET_BALANCE: 'SUBMIT_GET_BALANCE',
    GET_BSCPAD_BALANCE: 'GET_BSCPAD_BALANCE',
    GET_BNB_BALANCE: 'GET_BNB_BALANCE',
    SET_JOB_GET_BALANCE: 'SET_JOB_GET_BALANCE',
    CLEAR_STAKING_JOB: 'CLEAR_STAKING_JOB',
    SET_STAKING_ENABLE: 'SET_STAKING_ENABLE',
    SET_JOB_GET_STAKING_STATUS: 'SET_JOB_GET_STAKING_STATUS',
    SET_LATEST_BLOCK: "SET_LATEST_BLOCK",


    // SET_NETWORK_CONNECTOR:'SET_NETWORK_CONNECTOR',

    SET_MESSAGE :'SET_MESSAGE',
    SET_SHOW_TRANSACTION_HASH_MODAL:'SET_SHOW_TRANSACTION_HASH_MODAL',


    GET_KYC_INFO:'GET_KYC_INFO',
    CLEAR_KYC_STATE: 'CLEAR_KYC_STATE',
}