import React from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useStakingInfo } from "../../../../hook/useState";

export const StakingNotice = ({ title, value, className = 'staking-card', ...rest }) => {
    const { t } = useTranslation();
    const stakingInfo = useStakingInfo();
    return stakingInfo["isStakingPaused"] ?
        <div className="bs-stepper-warning w-100 mb-4 text-white text-center" style={{ fontSize: '18px', fontWeight: '300', borderRadius: '15px', background: 'linear-gradient( 180deg, #F18A3A 0%, #F18A3A 100%)' }}>
            <i className="mdi mdi-alert-outline me-2" style={{ fontSize: '20px' }}>
            </i> {t('All staking functions are temporarily paused. Please check back again later.')}
        </div> : <></>
}