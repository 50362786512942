import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "web3modal";
import { ACTION_CONST, ROUTES } from "../../constants";
import { helpers } from "../../utils/helpers";
import exactMath from 'exact-math';
import { useActiveWeb3React } from "../../hook";
import {  Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useKycStatus, useLatestBlockNumber, useNftlBalance, useStakeAmount, useWeb3Utils } from "../../hook/useState";
import { getKYC } from "../../redux/services/kyc.api";
import { isMetamaskAvailable, isTrustWalletAvailable } from "../../utils/utils";
import { getAmountPadToken, getStakeAmountWallet } from '../../utils/contractHelpers'

const Header = () => {
  const dispatch = useDispatch();
  const { account, library, chainId } = useActiveWeb3React()
  const showModalHelp = useSelector((state) => get(state, "utils.showModalHelp", false));
  const latestBlock = useLatestBlockNumber();
  const web3Utils = useWeb3Utils();
  const nftlBalance = useNftlBalance();
  const kycStatus = useKycStatus();
  const stakeAmount = useStakeAmount();
  const [enableKYC, setEnableKYC] = useState(false);

  const [bscStaked, setBSCStaked] = useState(0);
  const [bscPadBalance, setBSCPadBalance] = useState(0);
  const [ethStaked, setETHStaked] = useState(0);
  const [ethPadBalance, setETHPadBalance] = useState(0);

  //get info account
  useEffect(() => {
    if (account) {
      getStakeAmountWallet(56, account).then(result => {
        // console.log("staked bsc==>", result);
        setBSCStaked(result)
      })
      getStakeAmountWallet(1, account).then(result => {
        // console.log("staked eth==>", result);
        setETHStaked(result);
      })

      getAmountPadToken(56, account).then(balance => {
        // console.log("balance bsc==>", balance);
        setBSCPadBalance(balance)
      })
      getAmountPadToken(1, account).then(balance => {
        // console.log("balance eth==>", balance);
        setETHPadBalance(balance)
      })
    }



  }, [account, latestBlock])

  //set balance
  useEffect(() => {
    if (web3Utils && account) {
      //get bnb balance
      web3Utils.web3.eth.getBalance(account).then(balance => {
        dispatch({
          type: ACTION_CONST.GET_BNB_BALANCE,
          data: exactMath.div(balance, 10 ** 18)
        })
      }).catch(err => {
        console.log(err);
      })

      //get NFTL balance
      web3Utils.getTokenPadBalance().then(data => {
        dispatch({
          type: ACTION_CONST.GET_BSCPAD_BALANCE,
          data: data
        })
        // setNFTLBalance(data)
      })
    }

  }, [account, web3Utils, latestBlock]);

  const [netWorkConnector, setNetworkConnect] = useState("ETH")
  useEffect(() => {
    if (chainId == 3 || chainId == 1) {
      setNetworkConnect("ETH")
    }
    if (chainId == 56 || chainId == 97) {
      setNetworkConnect("BSC")
    }
  }, [chainId])

  const handleOnclickKyc = () => {
    getKYC(account, 'url').then(data => {
      if (data) {

        const url = data.url
        if (isMobile() && (isMetamaskAvailable() || isTrustWalletAvailable()))
          window.location.href = url
        else
          window.open(url, '_blank');
      }

    }).catch(err => {
      console.log(err);
    })
  }



  //check show hide KYC status
  useEffect(() => {
    if (!account) {
      return;
    }
    if ((Number(bscStaked) + Number(bscPadBalance) + Number(ethStaked) + Number(ethPadBalance)) >= 1000.0) {

      setEnableKYC(true)

      getKYCAddress(account)

    } else {
      setEnableKYC(false)
    }

  }, [chainId, account, stakeAmount, latestBlock, nftlBalance])

  //get kyc
  const getKYCAddress = (address) => {
    getKYC(address, 'state').then(response => {
      address = address.toLowerCase()
      if (response) {

        const state = response.state

        console.log('address:', address)
        // console.log('state===>', state)
        if (state === 1) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'START'
          })
        }
        if (state === 2) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'PENDING'
          })
        }
        if (state === 3) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'APPROVED'
          })
        }
        if (state === 4) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'ERROR'
          })
        }
      }

    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="https://nftlaunch.network" target="blank">
            <img src="/images/logo_w.png" height="45" alt="NFTLAUNCHPAD" className="me-2" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar-nav">

              <Nav.Item className="nav-item">
                <Nav.Link as={NavLink} activeClassName="active" to={ROUTES.STACKING}>Staking</Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <Nav.Link as={NavLink} activeClassName="active" to={ROUTES.UN_STACKING}>UnStaking</Nav.Link>
              </Nav.Item>

              <Nav.Item className="nav-item">
                <a className="nav-link" href="https://app-ido.nftlaunch.network" target="_blank">Projects</a>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <a className="nav-link" href="https://app.nftlaunch.network" target="_blank">NFTs</a>
              </Nav.Item>
            </Nav>
            <Nav className="ms-auto mt-2 mt-lg-0 column-gap-12px">
              {
                !account ?
                  <Nav.Item className="w-mb-100 d-flex">
                    <Nav.Link as="button" className="btn btn-primary w-mb-100" type="button" data-bs-toggle="modal" data-bs-target={showModalHelp ? "#helpModal" : "#connectWalletModal"}>
                      <span>Connect Wallet</span>
                    </Nav.Link>
                    <span className="network-connected">{netWorkConnector}</span>
                  </Nav.Item>
                  :
                  <>
                    <Nav.Item className="w-mb-100 d-flex">
                      <Nav.Link as="button" className="nav-link btn btn-primary btn-balance" type="button" data-bs-toggle="modal" data-bs-target="#walletModal">
                        <span>{helpers.formatTransactionHash(account, 5, 5)}</span>&nbsp;-&nbsp;<b>{helpers.formatNumberDownRoundWithExtractMax(nftlBalance, 4)}</b>&nbsp;NFTL
                      </Nav.Link>
                      <span className="network-connected">{netWorkConnector}</span>
                    </Nav.Item>
                    {
                      enableKYC &&
                      <>
                        {kycStatus === 'START' &&
                          <Nav.Item className="w-mb-100 d-flex">
                            <Nav.Link as="button" className="nav-link btn-kyc btn btn-warning btn-sm" type="button" onClick={() => handleOnclickKyc()}>
                              <i className="mdi mdi-file-edit-outline me-1"></i>
                              <span>KYC</span>
                            </Nav.Link>
                          </Nav.Item>
                        }
                        {kycStatus === 'PENDING' &&
                          <Nav.Item className="w-mb-100 d-flex">
                            <Nav.Link as="button" className="nav-link btn-kyc btn btn-primary btn-sm" type="button" onClick={() => handleOnclickKyc()}>
                              <i className="mdi mdi-clock-outline me-1"></i>
                              <span>KYC</span>
                            </Nav.Link>
                          </Nav.Item>
                        }
                        {kycStatus === 'APPROVED' &&
                          <Nav.Item className="w-mb-100 d-flex">
                            <Nav.Link as="button" className="nav-link btn-kyc btn btn-success btn-sm" type="button" >
                              <i className="mdi mdi-check me-1"></i>
                              <span>KYC</span>
                            </Nav.Link>
                          </Nav.Item>
                        }
                        {kycStatus === 'ERROR' &&
                          <Nav.Item className="w-mb-100 d-flex">
                            <Nav.Link as="button" className="nav-link btn-kyc btn btn-danger btn-sm" type="button" onClick={() => handleOnclickKyc()}>
                              <i className="mdi mdi-close me-1"></i>
                              <span>KYC</span>
                            </Nav.Link>
                          </Nav.Item>
                        }
                      </>
                    }


                  </>
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
