import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../constants";
import OHTTooltip from "../../../Tooltip";
import packageJson from '../../../../../package.json';
const Socials = ({ isMobile = false }) => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <div className={`staking-card ${!isMobile ? 'd-lg-block d-none' : 'd-lg-none d-block'}`} style={{ background: '#ffffff1a', maxHeight: '250px' }}>
      <div className="staking-card-header">{t('NFTLaunch Socials')}</div>
      <div className="staking-card-body">
        <div className="d-flex align-items-center" style={{ gap: '15px' }}>

          <a href="https://t.me/NFTLaunchann" target="_blank">
            <OHTTooltip tooltip="NFTLaunch ANN">
              <i className="fab fa-telegram"></i>
            </OHTTooltip>
          </a>
          <a href="https://twitter.com/NFT_LAUNCH" target="_blank">
            <OHTTooltip tooltip="NFTLaunch Twitter">
              <i className="fab fa-twitter"></i>
            </OHTTooltip>
          </a>
          <a href="https://medium.com/@nftlaunch" target="_blank">
            <OHTTooltip tooltip="NFTLaunch Medium">
              <i className="fab fa-medium-m"></i>
            </OHTTooltip>
          </a>
          <a href="https://nftlaunch.network/" target="_blank">
            <OHTTooltip tooltip="NFTLaunch Website">
              <i className="far fa-globe"></i>
            </OHTTooltip>
          </a>
        </div>
        <div className="pp-footer">
          <div style={{ lineHeight: 1.5 }}>Copyright © {currentYear} NFTLaunch Company. All rights reserved - v{packageJson.version}</div>
          <hr />
          <div className="d-flex justify-content-center flex-wrap pb-3" style={{ gap: '10px' }}>
            <Link target="_blank" to={ROUTES.PRIVACY_POLICY}>Privacy Policy </Link>
            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <Link target="_blank" to={ROUTES.COOKIES_POLICY}>Cookies Policy </Link>
            <Link target="_blank" to={ROUTES.TERMS_OF_USE}>Terms of Use </Link>
          </div>
          {/* <div>version{packageJson.version}</div> */}
        </div>
      </div>
    </div>
  );
};

export default Socials;
