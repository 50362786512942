import { ACTION_CONST } from "../../constants";

const INITIAL_STATE = {
    walletInfo: {},

    stakingInfo: {},
    stakingWalletInfo: {},
    jobUnStakeTime: 0,
    nftlBalance: 0,
    ethBalance: 0,
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTION_CONST.GET_INFO_WALLET:
            return {
                ...state,
                walletInfo: action.data,
            };
        case ACTION_CONST.GET_KYC_INFO:
            return {
                ...state,
                kycStatus: action.data,
            };
        case ACTION_CONST.GET_KYC_3RD:
            return {
                ...state,
                kycURL: action.data,
            };
            //merge staking
        case ACTION_CONST.GET_STAKING_INFO:
            return {
                ...state,
                stakingInfo: action.data,
            };

        case ACTION_CONST.GET_STAKING_WALLET_INFO:
            return {
                ...state,
                stakingWalletInfo: action.data,
            };

        case ACTION_CONST.GET_BSCPAD_BALANCE:
            return {
                ...state,
                nftlBalance: action.data,
            };

        case ACTION_CONST.GET_BNB_BALANCE:
            return {
                ...state,
                ethBalance: action.data,
            };
        default:
            return state;
    }
};