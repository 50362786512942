import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ACTION_CONST, connectors, connectorLocalStorageKey } from '../../constants';
import useAuth from '../../hook/useAuth';
import Web3Helper from '../../utils/useWeb3Utils'
import { useActiveWeb3React } from '../../hook';

const ConnectWalletModal = () => {
    const dispatch = useDispatch();
    //show hide button

    const { login } = useAuth();
    const { account, library, error, chainId} = useActiveWeb3React()

    const handleConnectClick = useCallback((walletConfig) => {
        try {
            login(walletConfig.connectorId);
            window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId);
        } catch (error) {
            console.log(error);
        }

        // onDismiss()
    }, [])


    useEffect(() => {

        if (error)
            return;

        if (account && library && library.provider) {
            const web3 = new Web3Helper(library.provider, account, chainId)

            dispatch({
                type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
                data: web3
            });
            return;
        }

    }, [library, account, error])


    return (
        <>
            <div className="modal fade" id="connectWalletModal" tabIndex="-1" aria-labelledby="connectWalletModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="connectWalletModalLabel">Connect to wallet</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            {connectors.map((entry, key) => {
                                if (entry.enable === true) {
                                    return (
                                        <button type="button" key={key} data-bs-dismiss="modal"
                                            onClick={() => handleConnectClick(entry)}
                                            id={`wallet-connect-${entry.title.toLocaleLowerCase()}`}
                                            className="btn-select-network"
                                        >
                                            <img src={entry.icon} width="30px" alt="bscpad" />
                                            <div>{entry.title}</div>
                                        </button>
                                    )
                                }

                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ConnectWalletModal;


