import Web3 from "web3";

import erc20Abi from "../constants/abi/erc20.abi";
import IDOContractABI from "../constants/abi/ido.json";
import stakingABIContract from "../constants/abi/staking.json";
import { calculateBalanceSend } from "./utils";
import { BigNumber } from "bignumber.js";
import { STAKING_CONTRACT_ADDRESS, } from "../_configs";
import { ACTION_STATUS } from "../constants";

export default class Web3Helper {
    constructor(provider, account, chainId) {
        this.web3 = new Web3(provider);
        this.address = account;
        this.chainId = chainId;
    }

    getWeb3Helper(provider, account) {
        if (!Web3Helper.web3) {
            Web3Helper.web3 = new Web3Helper(provider, account);
        }

        return Web3Helper.web3;
    }

    async approve({ tokenContractAddress, contractAddress, amount }, callback) {
        amount = calculateBalanceSend(amount);
       
            const tokenContract = this.useERC20Contract(tokenContractAddress)

            callback({
                status: ACTION_STATUS.APPROVING,
            });
            const amountInHex = "0x" + amount.toString(16);
            const gasPrice = await this.web3.eth.getGasPrice();

            tokenContract.methods
                .approve(contractAddress, amountInHex)
                .send({ from: this.address, gasPrice }).then(response=>{
   
                    if(response.status){
                        callback({
                            status: ACTION_STATUS.APPROVED,
                            txID: response.transactionHash
                        });
                    }else{
                        callback({
                            status: ACTION_STATUS.APPROVE_FAILS,
                            txID: response.transactionHash
                        });  
                    }
                }).catch(error=>{
                    console.log(error);
                    callback({
                        status: ACTION_STATUS.APPROVE_FAILS,
                        txID: ""
                    }); 
                });
              
           
       
    }



    async getTokenBalance(tokenAddress) {
        const tokenContract = this.useERC20Contract(tokenAddress)
        const tokenBalance = await tokenContract.methods
            .balanceOf(this.address)
            .call();

        return new BigNumber(tokenBalance.toString())
            .dividedBy(10 ** 18)
            .toFixed(18)
            .replace(/\.?0+$/, "")
            .toString();
    }

    async getAllowance(tokenAddress, contractAddress) {
        const tokenContract = this.useERC20Contract(tokenAddress)
        const allocationNumber = await tokenContract.methods
            .allowance(this.address, contractAddress)
            .call();
        return new BigNumber(allocationNumber.toString())
            .dividedBy(10 ** 18)
            .toString();
    }

    async getTokenPadBalance() {
        try {
            const contract = this.useStakingContract()
            const tokenAddress = await contract.methods.token().call();
            return await this.getTokenBalance(tokenAddress);
        } catch (error) {
            console.log(error);
            return 0;
        }

    }

    async stakingDeposit({ amount }, callback) {
        const contract = this.useStakingContract()
        amount = calculateBalanceSend(amount);
        const amountInHex = "0x" + amount.toString(16);
        try {
            const gasPrice = await this.web3.eth.getGasPrice();

            const depositResult = await contract.methods
                .stake(amountInHex)
                .send({ from: this.address, gasPrice })
                .on("error", (error) => {
                    console.log(error);
                    callback({
                        status: ACTION_STATUS.STAKING_DEPOSIT_FAIL,
                    });
                })
                .on("transactionHash", (hash) => {
                    callback({
                        status: ACTION_STATUS.STAKING_DEPOSIT_SUBMIT,
                        txID: hash,
                    });
                })
                .then((receipt) => {
                    if (receipt.status === true) {
                        callback({
                            status: ACTION_STATUS.STAKING_DEPOSIT_SUCCESS,
                            txID: receipt.transactionHash,
                        });
                    } else callback({ status: ACTION_STATUS.STAKING_DEPOSIT_FAIL });
                })
                .catch((err) => {
                    console.log(err);
                    callback({ status: ACTION_STATUS.STAKING_DEPOSIT_FAIL });
                });
            return depositResult;
        } catch (e) {
            console.error(e.message);
            callback({
                status: ACTION_STATUS.STAKING_DEPOSIT_FAIL,
            });
            return e.message;
        }
    }

    //request withdraw staking
    async stakingInitiateWithdrawal({ amount }, callback) {
        const contract = this.useStakingContract();
        amount = calculateBalanceSend(amount);
        const amountInHex = "0x" + amount.toString(16);
        try {
            const gasPrice = await this.web3.eth.getGasPrice();
            const initiateWithdrawalResult = await contract.methods
                .unstake(amountInHex)
                .send({ from: this.address, gasPrice})
                .on("transactionHash", (hash) => {
                    callback({
                        status: ACTION_STATUS.STAKING_INITIATE_WITHDRAWAL_SUBMIT,
                        txID: hash,
                    });
                })
                .on("error", (error) => {
                    console.log(error);
                    callback({
                        status: ACTION_STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL,
                    });
                })
                .then((receipt) => {
                    if (receipt.status === true) {
                        callback({
                            status: ACTION_STATUS.STAKING_INITIATE_WITHDRAWAL_SUCCESS,
                            txID: receipt.transactionHash,
                        });
                    } else callback({ status: ACTION_STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL });
                })
                .catch((err) => {
                    console.log(err);
                    callback({ status: ACTION_STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL });
                });
            return initiateWithdrawalResult;
        } catch (e) {
            console.error(e.message);
            callback({
                status: ACTION_STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL,
            });
            return e.message;
        }
    }

    // execute withdraw staking
    async stakingExecuteWithdrawal(callback) {
        const contract = this.useStakingContract()
        try {
            const gasPrice = await this.web3.eth.getGasPrice();

            const executeWithdrawalResult = await contract.methods
                .withdraw()
                .send({ from: this.address, gasPrice})
                .on("transactionHash", (hash) => {
                    callback({
                        status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAWAL_SUBMIT,
                        txID: hash,
                    });
                })
                .on("error", (error) => {
                    console.log(error);
                    callback({
                        status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAWAL_FAIL,
                    });
                })
                .then((receipt) => {
                    if (receipt.status === true) {
                        callback({
                            status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAWAL_SUCCESS,
                            txID: receipt.transactionHash,
                        });
                    } else callback({ status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAWAL_FAIL });
                })
                .catch((err) => {
                    console.log(err);
                    callback({ status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAWAL_FAIL });
                });
            return executeWithdrawalResult;
        } catch (e) {
            console.error(e.message);
            callback({
                status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAWAL_FAIL,
            });
            return e.message;
        }
    }

    // execute withdraw rewards
    async stakingExecuteWithdrawRewards(callback) {

        const contract = this.useStakingContract()

        try {
            const gasPrice = await this.web3.eth.getGasPrice();
            const executeWithdrawRewardsResult = await contract.methods
                .withdrawRewards()
                .send({ from: this.address, gasPrice })
                .on("transactionHash", (hash) => {
                    callback({
                        status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_SUBMIT,
                        txID: hash,
                    });
                })
                .on("error", (error) => {
                    console.log(error);
                    callback({
                        status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL,
                    });
                })
                .then((receipt) => {
                    if (receipt.status === true) {
                        callback({
                            status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_SUCCESS,
                            txID: receipt.transactionHash,
                        });
                    } else callback({ status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL });
                })
                .catch((err) => {
                    console.log(err);
                    callback({ status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL });
                });
            return executeWithdrawRewardsResult;
        } catch (e) {
            console.error(e.message);
            callback({
                status: ACTION_STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL,
            });
            return e.message;
        }
    }

    async stakingRewards(callback) {

        // const contract = this.useStakingContract()

        try {
            const gasPrice = await this.web3.eth.getGasPrice();

            const executeStakeRewardsResult = await  this.useStakingContract().methods
                .stakeRewards()
                .send({ from: this.address, gasPrice })
                .on("transactionHash", (hash) => {
                    callback({
                        status: ACTION_STATUS.STAKING_REWARDS_SUBMIT,
                        txID: hash,
                    });
                })
                .on("error", (error) => {
                    console.log(error);
                    callback({
                        status: ACTION_STATUS.STAKING_REWARDS_FAIL,
                    });
                })
                .then((receipt) => {
                    if (receipt.status === true) {
                        callback({
                            status: ACTION_STATUS.STAKING_REWARDS_SUCCESS,
                            txID: receipt.transactionHash,
                        });
                    } else callback({ status: ACTION_STATUS.STAKING_REWARDS_FAIL });
                })
                .catch((err) => {
                    console.log(err);
                    callback({ status: ACTION_STATUS.STAKING_REWARDS_FAIL });
                });
            return executeStakeRewardsResult;
        } catch (e) {
            console.error(e.message);
            callback({
                status: ACTION_STATUS.STAKING_REWARDS_FAIL,
            });
            return e.message;
        }
    }

    async getStakingInfoWallet() {
        try {
            const contract = this.useStakingContract();


            const infoWallet = await contract.methods.infoWallet(this.address).call();
            // console.log("infoWallet==>", infoWallet);
            const infoWalletFees = await contract.methods.infoWalletFees(this.address).call();

            return {
                stakedAmount: BigNumber(infoWallet[0].toString())
                    .dividedBy(10 ** 18)
                    .toString(),
                unstakedAmount: BigNumber(infoWallet[1].toString())
                    .dividedBy(10 ** 18)
                    .toString(),
                depositTimestamp: Number(infoWallet[2]) * 1000,
                lastUnstakeTimestamp: Number(infoWallet[3]) * 1000,
                withdrawTimestamp: Number(infoWallet[4]) * 1000,
                rewardAmount: BigNumber(infoWallet[5].toString())
                    .dividedBy(10 ** 18)
                    .toFixed(18)
                    .replace(/\.?0+$/, "")
                    .toString(),
                stakedDuration: Number(infoWalletFees[0]),
                totalPctFee: Number(infoWalletFees[1]),
            };
        } catch (error) {
            console.log(error);
            return {}
        }

    }


    useStakingContract() {
        return new this.web3.eth.Contract(stakingABIContract, STAKING_CONTRACT_ADDRESS[this.chainId]);
    }
    useIDOContract(contractAddress) {
        return new this.web3.eth.Contract(IDOContractABI, contractAddress);
    }
    useERC20Contract(tokenAddress) {
        return new this.web3.eth.Contract(erc20Abi, tokenAddress);
    }
}


