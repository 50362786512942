export const POOL_INTERVAL = 15000;

export const EXPLORER = {
  1: "https://etherscan.io",
  3: "https://ropsten.etherscan.io",
  56: "https://bscscan.com",
  97: "https://testnet.bscscan.com",
};

export const STAKING_CONTRACT_ADDRESS = {
  1: "0x173A5c5E0106ca4F49cE91F042e32af97974035B",
//   3:'0x1c5489334AbD6f527b00C23206a818321fedd406',
  56: "0x89D92bE368eEFE1fB9d83988d78E5b8EB518C695",
//   97: "0x4Bd8C3749AEa9373C11d3340b51a827AbA58459e",
};
export const NODE_RPC = {
  1: process.env.REACT_APP_NODE_INFURA_URI_1,
  3: "https://ropsten.infura.io/v3/f2473914890349138c8b03e3ef79d165",
  56: "https://bsc-dataseed.binance.org/",
  97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
};

export const ETH_TOKEN_REWARD__BCS_MAINNET = {
  56: {
    tokenAddress: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    tokenSymbol: "ETH",
    tokenDecimals: "18",
    image:'https://bscscan.com/token/images/ethereum_32.png'
  },
};


export const defaultChainId = 1;

export const chainSupport = [1, 56];


export const KYC_BACKEND_URI = 'https://bscpad.com';