import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery';
import { get } from "lodash";
import { Link } from "react-router-dom";
import { ACTION_CONST, ACTION_STATUS, MESSAGE, ROUTES } from "../../constants";
import { STAKING_CONTRACT_ADDRESS, EXPLORER } from "../../_configs";
import { Button } from "react-bootstrap";
import { BigNumber } from "bignumber.js";
import { helpers } from "../../utils";
import { useTranslation } from "react-i18next";
import { StakingLayout } from "../../component/layout/StakingLayout";
import { useActiveWeb3React } from "../../hook";

import { useEthBalance, useNativeCoin, useNetWork, useNftlBalance, useStakingInfo, useStakingWalletInfo, useWeb3Utils } from "../../hook/useState";

const StakingPage = (props) => {

    const { chainId, account } = useActiveWeb3React()
    const { t } = useTranslation();
    const dispatch = useDispatch();
    // const isConnectWallet = useConnectWallet()
    const web3Utils = useWeb3Utils()
    const stakingInfo = useStakingInfo()
    const stakingWalletInfo = useStakingWalletInfo()
    const ethBalance = useEthBalance();
    const nftlBalance = useNftlBalance()
    const [acceptTerm, setAcceptTerm] = useState(false);

    const [swapAmount, setSwapAmount] = useState('0')
    const [isMaxAmount, setIsMaxAmount] = useState(false);

    const [swapCurrentStep, setSwapCurrentStep] = useState(1);
    const [enableSwapBtn, setEnableSwapBtn] = useState(false);

    const [transactionHash, setTransactionHash] = useState("")
    const [isSubmitOK, setIsSubmitOK] = useState(false);


    useEffect(() => {
        if (swapCurrentStep === 1) {
            if (acceptTerm &&
                account &&
                !stakingInfo["isStakingPaused"] &&
                parseFloat(nftlBalance) > 0 &&
                ethBalance > 0 &&
                stakingWalletInfo["withdrawTimestamp"] === 0
            ) {
                setEnableSwapBtn(true);
            } else {
                setEnableSwapBtn(false);
            }
        }
    }, [acceptTerm, account, ethBalance, stakingInfo, stakingWalletInfo, swapCurrentStep, nftlBalance])


    useEffect(() => {

        setSwapStepActive();

    }, [swapCurrentStep])

    const swapBack = () => {
        if (swapCurrentStep === 1) {
            return;
        } else {
            setSwapCurrentStep(swapCurrentStep - 1);
        }
    }

    const swapNext = async () => {
        if (swapCurrentStep === 5) return;

        if (swapCurrentStep === 1 && parseFloat(swapAmount) === 0) {
            setEnableSwapBtn(false);
            setSwapCurrentStep(swapCurrentStep + 1);
            return;
        }

        if (swapCurrentStep === 3) {
            // click approve
            if (web3Utils) {
                dispatch({type: ACTION_CONST.REQUEST_SUBMIT});
 
                const allowance = await web3Utils.getAllowance(stakingInfo["tokenAddr"], STAKING_CONTRACT_ADDRESS[chainId]);

                if (new BigNumber(allowance).gte(isMaxAmount ? new BigNumber(nftlBalance) : new BigNumber(swapAmount))) {

                    setSwapCurrentStep(4);
                    return dispatch({type: ACTION_CONST.REQUEST_DONE });

                }
                web3Utils.approve({ tokenContractAddress: stakingInfo["tokenAddr"], contractAddress: STAKING_CONTRACT_ADDRESS[chainId], amount: isMaxAmount ? nftlBalance : swapAmount }, (data) => {
                    //function success
                    if (data.status === ACTION_STATUS.APPROVED) {
                        dispatch({type: ACTION_CONST.REQUEST_SUCCESS});
                        // dispatch({type: ACTION_CONST.SET_MESSAGE, data: MESSAGE.APPROVE_SUCCESS});
                        dispatch({type: ACTION_CONST.SET_SHOW_TRANSACTION_HASH_MODAL, data: data.txID});
                        setSwapCurrentStep(4);
                    }
                    //function fails
                    if (data.status === ACTION_STATUS.APPROVE_FAILS) {
                      
                        dispatch({type: ACTION_CONST.SET_MESSAGE, data: MESSAGE.APPROVAL_FAILS});
                        // setMessageFails(MESSAGE.APPROVAL_FAILS)
                        dispatch({type: ACTION_CONST.REQUEST_FAIL});
                    }
                })

            }
        } else if (swapCurrentStep === 4) {
            // click deposit
            if (web3Utils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                web3Utils.stakingDeposit({ amount: isMaxAmount ? nftlBalance : swapAmount }, (result) => {
                    if (result.status === ACTION_STATUS.STAKING_DEPOSIT_SUCCESS) {
                        dispatch({type: ACTION_CONST.REQUEST_SUCCESS})
                        setIsSubmitOK(true)
                        setTransactionHash(result.txID)
                        dispatch({type: ACTION_CONST.SET_SHOW_TRANSACTION_HASH_MODAL, data: result.txID});
                        //change to result screen
                        setSwapCurrentStep(5);

                    }
                    if (result.status === ACTION_STATUS.STAKING_DEPOSIT_FAIL) {
                        dispatch({type: ACTION_CONST.REQUEST_FAIL})
                        dispatch({type: ACTION_CONST.SET_MESSAGE, data: MESSAGE.DEPOSIT_STAKE_FAIL});
    
                    }
                })
            }
        } else {
            setSwapCurrentStep(swapCurrentStep + 1);
        }
    }


    //handle input swap change
    const handleInputSwap = (e) => {
        setIsMaxAmount(false);

        if (helpers.isFloatFormatted(e.target.value, 4)) {
            setSwapAmount(e.target.value);

            //check input
            if (BigNumber(e.target.value).lte(BigNumber(nftlBalance)) && BigNumber(e.target.value).gt(BigNumber(0))) {
                setEnableSwapBtn(true);
            } else {
                setEnableSwapBtn(false);
            }
        }
    }

    const submitDone = () => {
        setSwapAmount('0');
        setIsMaxAmount(true);
        setSwapCurrentStep(1);
        setIsSubmitOK(false);
    }

    const handleMaxButtonClick = () => {
        setSwapAmount(helpers.formatNumberDownRound(nftlBalance, 4));
        setEnableSwapBtn(true);
        setIsMaxAmount(true);
    }

    const setSwapStepActive = () => {
        $('#stake .bs-stepper-header .step').removeClass('active');
        $('#stake .bs-stepper-header .line').removeClass('active');
        for (var i = 1; i <= 5; i++) {
            if (i <= swapCurrentStep) {
                $('#stake #swapButtonStep' + i).addClass('active');
                $('#stake #swapLineStep' + (i - 1)).addClass('active');
            }
        }
        $('#stake .bs-stepper-content').hide();
        $('#stake #SwapStep' + swapCurrentStep).show();
    }


    return (
        <StakingLayout title={t('Stake your NFTL')}>
            <div id="stake">
                <div className="row justify-content-center mb-4">
                    <div className="col-lg-12">
                        <div className="bs-stepper w-100">
                            <div className="bs-stepper-header" role="tablist">
                                <div className="step active" id="swapButtonStep1">
                                    <button type="button" className="step-trigger ps-0">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label first">Checkpoints</span>
                                    </button>
                                </div>
                                <div className="line" id="swapLineStep1" />
                                <div className="step" id="swapButtonStep2">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label">Amount Stake</span>
                                    </button>
                                </div>
                                <div className="line" id="swapLineStep2" />
                                <div className="step" id="swapButtonStep3">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label">Pre-authorization</span>
                                    </button>
                                </div>
                                <div className="line" id="swapLineStep3" />
                                <div className="step" id="swapButtonStep4">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label">Confirm</span>
                                    </button>
                                </div>
                                <div className="line" id="swapLineStep4" />
                                <div className="step" id="swapButtonStep5">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label last">Confirmation</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="p-sidebar-card p-sidebar-card-steps">
                            <div className="p-sidebar-card-body">
                                <div className="bs-stepper-content" id="SwapStep1">
                                    <div className="text-center">
                                        <h4>{t('Checkpoints')}</h4>
                                        <p>{t('The following conditions must be met to proceed')}:</p>
                                    </div>
                                    <div className="row gx-xl-4 gx-lg-5 mt-5 d-flex justify-content-center">
                                        <div className="col-xl-3 col-md-6 mb-lg-0 mb-3">
                                            <div className={account ? "p-select-card mb-4 selected" : "p-select-card mb-4"}
                                            // onClick={() => { $(this).toggleClass('selected') }}
                                            >
                                                <div className="p-select-card-title"><b>{t('Connected with MetaMask')}</b></div>
                                                <div className="p-select-card-description">{t("If not connected, click the \"Connect Wallet\" button in the top right corner")}</div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-6 mb-lg-0 mb-3">
                                            <div className={parseFloat(nftlBalance) > 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}
                                            // onClick={() => { $(this).toggleClass('selected') }}
                                            >
                                                <div className="p-select-card-title"><b> {t('NFTL available to deposit')}</b></div>
                                                <div className="p-select-card-description">{t('Current Balance')}: {helpers.formatNumberDownRoundWithExtractMax(nftlBalance, 4)}</div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-6 mb-lg-0 mb-3">
                                            <div className={ethBalance > 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}
                                            // onClick={() => { $(this).toggleClass('selected') }}
                                            >
                                                <div className="p-select-card-title"><b>{useNativeCoin(chainId)} available in wallet</b></div>
                                                <div className="p-select-card-description">
                                                    {useNativeCoin(chainId)} is required to pay transaction fees on the {useNetWork(chainId)}.<br />
                                                    {useNativeCoin(chainId)} Balance: {helpers.formatNumberDownRoundWithExtractMax(ethBalance, 4)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-6 mb-lg-0 mb-3">
                                            <div className={stakingWalletInfo["withdrawTimestamp"] === 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                                <div className="p-select-card-title"><b>{t('Eligible to stake')}</b></div>
                                                <div className="p-select-card-description">{t('You cannot stake if you have an active NFTL unstake/withdrawal request')}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 text-center">
                                        <div className="form-check">
                                            <input className="form-check-input float-none me-1"
                                                type="checkbox" defaultValue id="flexCheckDefault"

                                                onChange={() => setAcceptTerm(!acceptTerm)} />
                                            <label className="form-check-label ms-1" htmlFor="flexCheckDefault">
                                                {t('I have read the')}&nbsp;
                                                <Link target="_blank" to={ROUTES.TERMS_OF_USE}>{t('Terms and Conditions')} </Link>
                                                {/* <a href="#">Terms and Conditions</a> */}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="bs-stepper-content" id="SwapStep2">
                                    <div className="text-center">
                                        <h4>{t('Please enter the amount of NFTL you want to stake')}</h4>
                                        <p className="stake-amount-note">Any ETH rewards already accumulated will be automatically paid out when staking NFTL.</p>
                                        <p className="stake-amount-note">Any fees applied at Unstake & Withdraw stage will be based on the date you last staked.</p>
                                        <div className="mx-auto text-start mt-5" style={{ maxWidth: '370px' }}>
                                            <div className="p-form-group mb-1">
                                                <label className="form-label">{t('Amount')}</label>
                                                <div className="input-group">
                                                    <input type="number" className="form-control fs-24" placeholder={0.0} value={swapAmount} onChange={(e) => handleInputSwap(e)} />
                                                    <Button className="mw-0"
                                                        onClick={handleMaxButtonClick}
                                                    >
                                                        {t('MAX')}
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-start justify-content-between">
                                                <div className="font-14">{t('Balance')}: <b className="text-warning">{helpers.formatNumberDownRound(nftlBalance, 4)}</b></div>
                                                <div />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bs-stepper-content" id="SwapStep3">
                                    <div className="text-center">
                                        <h4>{t('Pre-authorization')}</h4>
                                        <p>{t('Required transaction 1 of 2')}</p>
                                        <p>{t('In this step, you grant access to the staking smart contract to accept your NFTL')}</p>
                                    </div>
                                    <div className="text-center mt-4">
                                        <div className="p-progress-waiting">
                                            <div className="p-progress-percent" style={{ width: '60%' }} />
                                        </div>
                                        {/* <h4>{t('Waiting for the transaction to complete')}</h4> */}
                                        <p>{t('Please wait for the transaction to confirm before proceeding.')}</p>
                                        <p><a className="p-address" href={`${EXPLORER[chainId]}/address/${account}`} target="blank">{account}</a></p>
                                    </div>
                                </div>
                                <div className="bs-stepper-content" id="SwapStep4">
                                    <div className="text-center">
                                        <h4>{t('Confirm')}</h4>
                                        <p>{t('Required transaction 2 of 2')}</p>
                                        <p>
                                            {t('In this step, you deposit the tokens into the staking contract.')}<br />
                                            {t('After this step, your tokens will be successfully staked.')}
                                        </p>
                                    </div>
                                </div>
                                <div className="bs-stepper-content" id="SwapStep5">
                                    <div className="text-center">
                                        <div className="text-warning">
                                            <i className="mdi mdi-check" style={{ fontSize: '50px' }} />
                                        </div>
                                        <h4>{t('Success')}</h4>
                                        <p>
                                            {t('Congratulations! Your tokens are now staked.')}<br />
                                            If desired, you may check {useNetWork(chainId)} to confirm the transaction.
                                        </p>
                                        <p><a className="p-address" href={`${EXPLORER[chainId]}/tx/${transactionHash}`} target="blank">{transactionHash}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-3 mt-5 text-center step-buttons">
                    {
                        !isSubmitOK ?
                            <>
                                <button onClick={() => swapBack()} type="button" disabled={(!enableSwapBtn || swapCurrentStep === 1)}
                                    className="btn btn-primary btn-back-step me-3"><i className="mdi mdi-arrow-left me-2" />{t('Previous')}</button>
                                <button onClick={() => swapNext()} type="button" disabled={(!enableSwapBtn || swapCurrentStep === 5 )}
                                    className="btn btn-primary btn-next-step">{t('Next')}<i className="mdi mdi-arrow-right ms-2" /></button></>
                            :
                            <>
                                <button onClick={() => submitDone()} type="button"
                                    className="btn btn-primary me-3">{t('Done')}</button>
                            </>
                    }
                </div>
            </div>
        </StakingLayout>
    );
};

export default StakingPage;
