import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { helpers } from '../../../utils';
import { StakingCard } from './components/StakingCard';
import { get } from "lodash";
import { getStakingContractInfo } from '../../../utils/contractHelpers';
import { ACTION_CONST } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StakingNotice } from './components/StakingNotice';
import RightBar from './components/RightBar';
import { useActiveWeb3React } from '../../../hook';
import TransactionConfirmationModal from '../../TransactionConfirmationModal/TransactionConfirmationModal';
import { useErrorModal, useMessage, useSuccessModal, useTransactionHash, useWaitingModal } from '../../../hook/useState';
import TransactionErrorContent from '../../TransactionConfirmationModal/TransactionErrorContent';

export const StakingLayout = ({ title, children, ...rest }) => {
    const {chainId} = useActiveWeb3React()
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [stakerCount, setStakerCount] = useState(0);
    const [totalStakedAmount, setTotalStakedAmount] = useState(0);
    const [apyPercentage, setApyPercentage] = useState(0);
    const latestBlock = useSelector((state) => get(state, "utils.latestBlock", 0));
    const submitOK = useSelector((state) => get(state, "utils.blocking", false));


    useEffect(() => {
        try {
            getStakingContractInfo(chainId).then(data => {
                setStakerCount(data.stakerCount);
                setTotalStakedAmount(data.totalStakedAmount);
                setApyPercentage(data.apyPercentage);
                dispatch({ type: ACTION_CONST.GET_STAKING_INFO, data: data });
            })

        } catch (error) {
            console.log(error);
        }
    }, [dispatch, submitOK, latestBlock, chainId]);


    const openWaitingModal = useWaitingModal()
    const openErrorModal = useErrorModal()
    const openSuccessModal = useSuccessModal();
    const message = useMessage()
    const textHash = useTransactionHash()

    const clearState = ()=>{
        dispatch({type: ACTION_CONST.REQUEST_DONE})
        dispatch({type: ACTION_CONST.SET_MESSAGE, data: ""});
        dispatch({type: ACTION_CONST.SET_SHOW_TRANSACTION_HASH_MODAL, data: ""});
    }

    return (
        <div className="staking">
            <TransactionConfirmationModal isOpen={openWaitingModal}  attemptingTxn={true} pendingText="" />

            <TransactionConfirmationModal isOpen={openSuccessModal} onDismiss={() => clearState()} hash={textHash} message={message}/>


            <TransactionConfirmationModal isOpen={openErrorModal} onDismiss={() => clearState()}>
                <TransactionErrorContent message={message} onDismiss={() => clearState()} />
            </TransactionConfirmationModal>
            <Container>
                <div className="staking-title">
                    <h1>{title}</h1>
                </div>

                <StakingNotice />
               
                <Row className="gx-lg-5">
                    <Col xl="9" lg="8" className="order-2 order-lg-1">
                        <div className="staking-header">
                            <Row>
                                <Col xl="4" lg="6">
                                    <StakingCard title={t('Number of Stakers')} value={stakerCount} className="style-1" />
                                </Col>
                                <Col xl="4" lg="6">
                                    <StakingCard title={t('Total NFTL Staked')} value={helpers.formatNumberDownRound(totalStakedAmount, 4)} className="style-2" />
                                </Col>
                                <Col xl="4" lg="6">
                                    <StakingCard title={t('APY')} value={`${helpers.formatNumberDownRound((apyPercentage / 100).toString(), 2)}%`} className="style-3" />
                                </Col>
                            </Row>
                        </div>
                        <div className="staking-content">
                            {children}
                        </div>
                    </Col>
                    <Col xl="3" lg="4" className="order-1 order-lg-2">
                        <RightBar />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};