import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery';
import { get } from "lodash";
import { Link } from "react-router-dom";
import { ACTION_CONST, ACTION_STATUS, ROUTES } from "../../constants";
import { EXPLORER } from "../../_configs";
import { helpers } from "../../utils";
import { useTranslation } from "react-i18next";
import { useActiveWeb3React } from "../../hook";
import { StakingLayout } from "../../component/layout/StakingLayout";

const WithDrawPage = () => {
    const {chainId} = useActiveWeb3React()
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [withdrawCurrentStep, setWithdrawCurrentStep] = useState(1);
    const isConnectWallet = useSelector((state) => get(state, "utils.isConnectWallet", false));
    const web3Utils = useSelector((state) => get(state, "utils.web3Utils", null));
    const { account, library } = useActiveWeb3React()
    const stakingInfo = useSelector((state) => get(state, "wallet.stakingInfo", {}));
    const stakingWalletInfo = useSelector((state) => get(state, "wallet.stakingWalletInfo", {}));
    const [acceptTerm, setAcceptTerm] = useState(false);
    const [enableWithDrawBtn, setEnableWithDrawBBtn] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");
    const [ethBalance, setethBalance] = useState(0);
    const [isSubmitOK, setIsSubmitOK] = useState(false);

    /* WITHDRAW */
    useEffect(() => {
        setWithdrawStepActive();
    }, [setWithdrawStepActive, withdrawCurrentStep])


    //get Info when connect wallet
    useEffect(() => {
        if (web3Utils && account) {

            //check ETH Balance
            web3Utils.web3.eth.getBalance(account).then(data => {
                setethBalance(data / 10 ** 18)
            })
            //get NFTL stake
        }

    }, [web3Utils, account])

    //enable withdraw button
    useEffect(() => {
        if (acceptTerm && isConnectWallet && ethBalance > 0 && !stakingInfo["isStakingPaused"] && stakingWalletInfo["withdrawTimestamp"] > 0 && stakingWalletInfo["withdrawTimestamp"] <= (new Date().getTime())) {
            setEnableWithDrawBBtn(true);
        } else {
            setEnableWithDrawBBtn(false);
        }
    }, [acceptTerm, isConnectWallet, ethBalance, stakingInfo, stakingWalletInfo])

    const withdrawBack = () => {
        if (withdrawCurrentStep == 1) {
            return;
        } else {
            setWithdrawCurrentStep(withdrawCurrentStep - 1);
        }
    }

    const withdrawNext = () => {
        if (withdrawCurrentStep === 2) {
            if (web3Utils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                web3Utils.stakingExecuteWithdrawal(data => {
                    if (data.status === ACTION_STATUS.STAKING_EXECUTE_WITHDRAWAL_SUCCESS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true)
                        setTransactionHash(data.txID)
                        setWithdrawCurrentStep(3);
                    }
                    if (data.status === ACTION_STATUS.STAKING_EXECUTE_WITHDRAWAL_FAIL) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })

                        dispatch({
                            type: ACTION_CONST.ALERT_FAILS,
                            message: t("Withdraw stake fail")
                        })
                    }
                })
            }
        } else {
            if (withdrawCurrentStep == 3) {
                return;
            } else {
                setWithdrawCurrentStep(withdrawCurrentStep + 1);
            }
        }
    }

    const submitDone = () => {
        setWithdrawCurrentStep(1);
        setIsSubmitOK(false);
    }

    function setWithdrawStepActive() {
        $('#withdraw .bs-stepper-header .step').removeClass('active');
        $('#withdraw .bs-stepper-header .line').removeClass('active');
        for (var i = 1; i <= 3; i++) {
            if (i <= withdrawCurrentStep) {
                $('#withdraw #withdrawButtonStep' + i).addClass('active');
                $('#withdraw #withdrawLineStep' + (i - 1)).addClass('active');
            }
        }
        $('#withdraw .bs-stepper-content').hide();
        $('#withdraw #WithdrawStep' + withdrawCurrentStep).show();
    }

    return (
        <StakingLayout title={t('Withdraw your NFTL')}>
            <div id="withdraw">
                <div className="row justify-content-center mb-4">
                    <div className="col-lg-12">
                        <div className="bs-stepper w-100">
                            <div className="bs-stepper-header" role="tablist">
                                <div className="step active" id="withdrawButtonStep1">
                                    <button type="button" className="step-trigger ps-0">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label first">Checkpoints</span>
                                    </button>
                                </div>
                                <div className="line" id="withdrawLineStep1" />
                                <div className="step" id="withdrawButtonStep2">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label">Initialize Withdrawal</span>
                                    </button>
                                </div>
                                <div className="line" id="withdrawLineStep2" />
                                <div className="step" id="withdrawButtonStep3">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label last">Confirmation</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="p-sidebar-card p-sidebar-card-steps">
                            <div className="p-sidebar-card-body">
                                <div className="bs-stepper-content" id="WithdrawStep1">
                                    <div className="text-center">
                                        <h4>{t('Checkpoints')}</h4>
                                        <p>{t('The following conditions must be met to proceed')}:</p>
                                    </div>
                                    <div className="row mt-5 gx-xl-4 gx-lg-5 d-flex justify-content-center">
                                        <div className="col-xl-3 col-md-6 mb-lg-0 mb-3">
                                            <div className={isConnectWallet ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                                <div className="p-select-card-title"><b>{t('Connected with MetaMask')}</b></div>
                                                <div className="p-select-card-description">{t('If not connected, click the "Connect Wallet" button in the top right corner')}</div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-6 mb-lg-0 mb-3">
                                            <div className={stakingWalletInfo["withdrawTimestamp"] > 0 && stakingWalletInfo["withdrawTimestamp"] <= (new Date().getTime()) ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                                <div className="p-select-card-title"><b>{t('Waiting period elapsed')}</b></div>
                                                <div className="p-select-card-description"></div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-6 mb-lg-0 mb-3">
                                            <div className={ethBalance > 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                                <div className="p-select-card-title"><b>{t('ETH available in wallet')}</b></div>
                                                <div className="p-select-card-description">
                                                    {t('ETH is required to pay transaction fees on the Ethereum Network.')}<br />
                                                    {t('ETH Balance')}: {helpers.formatNumberDownRoundWithExtractMax(ethBalance, 4)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-6 mb-lg-0 mb-3">
                                            <div className={stakingWalletInfo["withdrawTimestamp"] > 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                                <div className="p-select-card-title"><b>{t('Have Unstaked your NFTL')}</b></div>
                                                <div className="p-select-card-description"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 text-center">
                                        <div className="form-check">
                                            <input className="form-check-input float-none me-1"
                                                type="checkbox" defaultValue id="flexCheckDefault"
                                                onChange={() => setAcceptTerm(!acceptTerm)} />
                                            <label className="form-check-label ms-1" htmlFor="flexCheckDefault">
                                                {t('I have read the')}&nbsp;
                                                <Link target="_blank" to={ROUTES.TERMS_OF_USE}> {t('Terms and Conditions')} </Link>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="bs-stepper-content" id="WithdrawStep2">
                                    <div className="text-center">
                                        <h4>{t('Confirm Withdrawal')}</h4>
                                        <p>{t('In this step, you complete the transaction that withdraws your NFTL tokens')}</p>
                                    </div>
                                </div>
                                <div className="bs-stepper-content" id="WithdrawStep3">
                                    <div className="text-center">
                                        <h4>{t('Confirmed')}</h4>
                                        <div className="text-white">
                                            <i className="mdi mdi-check" style={{ fontSize: '50px' }} />
                                        </div>
                                        <p>
                                            {t('You have withdrawn your NFTL tokens.')}<br />
                                            {t('If desired, you may check Ethereum Network to confirm the transaction.')}
                                        </p>
                                        <p><a className="p-address" href={`${EXPLORER[chainId]}/tx/${transactionHash}`} target="blank">{transactionHash}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-3 mt-5 text-center step-buttons">
                    {!isSubmitOK ?
                        <>
                            <button onClick={() => withdrawBack()} type="button"
                                disabled={!enableWithDrawBtn || withdrawCurrentStep === 1}
                                className="btn btn-primary btn-back-step me-3"><i className="mdi mdi-arrow-left me-2" />{t('Previous')}</button>
                            <button onClick={() => withdrawNext()} type="button"
                                disabled={!enableWithDrawBtn || withdrawCurrentStep === 3}
                                className="btn btn-primary btn-next-step">{t('Next')}<i className="mdi mdi-arrow-right ms-2" /></button></>
                        :
                        <>
                            <button onClick={() => submitDone()} type="button"
                                className="btn btn-primary  me-3">{t('Done')}</button>
                        </>
                    }
                </div>
            </div>
        </StakingLayout>
    );
};

export default WithDrawPage;
