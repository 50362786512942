import React from "react"

export const StakingCard = ({ title, value, className = '', ...rest }) => {
    return <div className={`staking-card ${className}`} {...rest} >
        <div className="staking-card-header">
            <span>{title}</span>
        </div>
        <div className="staking-card-body">
            <span>{value}</span>
        </div>
    </div>
}