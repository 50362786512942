import React from 'react';
import "react-block-ui/style.css";
import Header from './layout/Header';
import Footer from './layout/Footer';
export const PrivateLayout = ({ children, ...rest }) => {

    return (
        <>
            {/* <BlockUi tag="div" blocking={showBlockUI}> */}
                <Header />
                {children}
                <Footer />
            {/* </BlockUi> */}
        </>
    );
};