import _ from "lodash";
import { defaultChainId, MODE, NODE_RPC } from "../_configs";

export const STATUS = {
    IDLE: 'idle',
    RUNNING: 'running',
    READY: 'ready',
    SUCCESS: 'success',
    ERROR: 'error',
};
export const connectorLocalStorageKey = 'connectorId';
export const ConnectorNames = {
    Injected: 'injected',
    // BSC : 'bsc',
    WalletConnect: 'WalletConnect',
}
export const ETH_NETWORK_CHAIN_ID = defaultChainId;
export const NETWORK_URL =NODE_RPC[defaultChainId]
export const NetworkContextName = 'NETWORK';