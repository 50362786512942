import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ACTION_CONST, ACTION_STATUS, MESSAGE } from "../../../../constants";
import { helpers } from "../../../../utils";
import { useActiveWeb3React } from "../../../../hook";
import { getCountDown } from "../../../../utils/helper";
import Socials from "./Socials";
import { useLatestBlockNumber, useSuccessModal, useWeb3Utils } from "../../../../hook/useState";
import { ETH_TOKEN_REWARD__BCS_MAINNET } from "../../../../_configs";
import { addTokenToMetamask } from "../../../../utils/metamaskUtils";

const RightBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { chainId, account, library } = useActiveWeb3React()
  const web3Utils = useWeb3Utils();
  const latestBlock = useLatestBlockNumber();
  const [stakedAmount, setStakedAmount] = useState('0');
  const [unstakedAmount, setUnstakedAmount] = useState('0');
  const [rewardAmount, setRewardAmount] = useState('0');
  const [withdrawTimestamp, setWithdrawTimestamp] = useState(0);

  // const [isSubmitOK, setIsSubmitOK] = useState(false);
  const submitOK = useSuccessModal()

  useEffect(() => {
    if (web3Utils && account && chainId) {
      // get stake amount
      web3Utils.getStakingInfoWallet().then(data => {
        setStakedAmount(data.stakedAmount);
        setUnstakedAmount(data.unstakedAmount);
        setRewardAmount(data.rewardAmount);
        const now = new Date().getTime();
        if (data.withdrawTimestamp > now) {
          setWithdrawTimestamp(data.withdrawTimestamp);
        }
        dispatch({ type: ACTION_CONST.GET_STAKING_WALLET_INFO, data: data })
      });
    }
  }, [web3Utils, account, submitOK, dispatch, withdrawTimestamp, latestBlock, chainId])

  useEffect(() => {
    if (withdrawTimestamp > 0) {
      getCountDown(`${account}-endtime`, withdrawTimestamp, (job) => {
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNTDOWN_STAKE_TIME,
          data: job
        });
      }, (job) => {
        setWithdrawTimestamp(0);
      });
    }
  }, [dispatch, account, withdrawTimestamp])

  //handle button withdraw click 
  const handleExcWithDraw = () => {
    if (web3Utils) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      web3Utils.stakingExecuteWithdrawRewards(data => {
        if (data.status === ACTION_STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_SUCCESS) {

          dispatch({ type: ACTION_CONST.SET_SHOW_TRANSACTION_HASH_MODAL, data: data.txID });
          dispatch({ type: ACTION_CONST.REQUEST_SUCCESS })
        }
        if (data.status === ACTION_STATUS.STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL) {
          dispatch({ type: ACTION_CONST.REQUEST_FAIL })

          dispatch({ type: ACTION_CONST.SET_MESSAGE, data: MESSAGE.WITHDRAW_FAIL });
        }
      })
    }
    //to do somrthing
  }

  //stake reward
  const handleExStakeRewards = async () => {
    if (web3Utils) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      web3Utils.stakingRewards(data => {
        if (data.status === "STAKING_REWARDS_SUCCESS") {
          dispatch({
            type: ACTION_CONST.REQUEST_SUCCESS
          })
          dispatch({
            type: ACTION_CONST.ALERT_SUCCESS,
            message: t("Stake rewards successfully")
          })

        }
        if (data.status === "STAKING_REWARDS_FAIL") {
          dispatch({
            type: ACTION_CONST.REQUEST_FAIL
          })
          dispatch({
            type: ACTION_CONST.ALERT_FAILS,
            message: t("Stake rewards fail")
          })
        }
      })
    }
  }


  const handleAddTokenToMetamask = () => {
    const data = ETH_TOKEN_REWARD__BCS_MAINNET[chainId]
    dispatch({
      type: ACTION_CONST.REQUEST_SUBMIT
    })
    addTokenToMetamask(data, (response) => {
      if (response.status === 'ADD_TOKEN_SUCCESS') {
        dispatch({ type: ACTION_CONST.SET_MESSAGE, data: MESSAGE.ADD_TOKEN_METAMASK_SUCCESS });
        dispatch({ type: ACTION_CONST.REQUEST_SUCCESS })
      }
      if (response.status === 'ADD_TOKEN_FAILS') {

        dispatch({
          type: ACTION_CONST.REQUEST_FAIL
        })
        dispatch({ type: ACTION_CONST.SET_MESSAGE, data: MESSAGE.ADD_TOKEN_METAMASK_FAIL });

      }

    })

  }

  return (
    <div className="p-sidebar" >
      <div className="staking-card style-4 pb-4" style={{ marginBottom: '25px' }}>
        <div className="p-sidebar-card">
          <div className="staking-card-header">{t('Staked')}</div>
          <div className="staking-card-body">{helpers.formatNumberDownRoundWithExtractMax(stakedAmount, 4)}</div>
        </div>
        <hr className="" />
        <div className="p-sidebar-card">
          <div className="staking-card-header">{t('Rewards')} (ETH)</div>
          <div className="staking-card-body">{helpers.formatNumberDownRoundWithExtractMax(rewardAmount, 6)}</div>
          <div className="staking-card-footer mt-4">
            <button className="btn btn-primary w-100"
              disabled={!account || parseFloat(rewardAmount) === 0 || parseFloat(unstakedAmount) > 0}
              onClick={() => handleExcWithDraw()}>{t('Withdraw')}</button>
          </div>
          {chainId === 56 &&
            <div className="staking-card-footer mt-4">
              <button className="btn btn-primary w-100"
                disabled={!account}
                onClick={() => handleAddTokenToMetamask()}>{t('+ Add To Metamask')}</button>
            </div>
          }

        </div>
      </div>

      <Socials />
    </div >
  );
};

export default RightBar;
