import React from 'react';
import ConnectWalletModal from '../modals/ConnectWalletModal';
import WalletModal from '../modals/WalletModal';
import HelpModal from '../modals/HelpModal';
import Socials from './StakingLayout/components/Socials';
import packageJson from '../../../package.json';
export default function Footer() {

  return (
    <>
      <div className="container">
       {console.log("version:",packageJson.version)}
        <Socials isMobile={true} />
      </div>
      <ConnectWalletModal />
      <HelpModal />
      <WalletModal />
    </>
  );
}